@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.font-space {
  font-family: "Space Mono", monospace;
}

.text-yg {
  color: #9acd32;
}

.bg-yg {
  background: #9acd32;
}

.btn .sec {
  top: 0.3rem;
  right: 0.3rem;
}
